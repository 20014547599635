import dayjs from 'dayjs';

import { Stack } from '@mui/material';

import { TrblPopup, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { Text } from '@/components/Shared/Text';

import { ResultPresetDto } from './types';

type InfoResultPresetPopupProps = {
  onClose: () => void;
  resultPreset: ResultPresetDto;
};

export const InfoResultPresetPopup: React.FC<InfoResultPresetPopupProps> = ({ onClose, resultPreset }) => {
  return (
    <TrblPopup width="400px" aria-labelledby="Details" open={true}>
      <TrblPopupTitle onClose={onClose}>Details</TrblPopupTitle>
      <TrblPopupContent>
        <Stack gap={2} marginBottom={2}>
          <Stack flexDirection="row" gap={1}>
            <Text type="medium-12px">Name:</Text>
            <Text type="regular-12px" style={{ lineHeight: 1.3, margin: '-2px 0' }}>
              {resultPreset.name}
            </Text>
          </Stack>

          <Stack flexDirection="row" gap={1}>
            <Text type="medium-12px">Created:</Text>
            <Text type="regular-12px">{dayjs(resultPreset.createdAt).format('MMM DD YYYY, HH:mm')}</Text>
          </Stack>
          <Stack flexDirection="row" gap={1}>
            <Text type="medium-12px">Created by:</Text>
            <Text type="regular-12px">{resultPreset.createdByUserEmail}</Text>
          </Stack>
          <Stack flexDirection="row" gap={1}>
            <Text type="medium-12px">Last updated by:</Text>
            <Text type="regular-12px">{resultPreset.updatedByUserEmail}</Text>
          </Stack>
          <Stack flexDirection="row" gap={1}>
            <Text type="medium-12px">Description:</Text>
            <Text type="regular-12px" style={{ lineHeight: 1.3, margin: '-2px 0' }}>
              {resultPreset.description || <i style={{ color: '#adadad' }}>None</i>}
            </Text>
          </Stack>
        </Stack>
      </TrblPopupContent>
    </TrblPopup>
  );
};
