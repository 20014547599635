import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';

import { config } from '@/__config__/config';

import { UserAccess } from '../Organization/types';

import axios from '@/axios';

const { adminApiUrl } = config;

type User = {
  id: string;
  organizationId: string | null;
  auth0Id: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  createdAt: string;
  trialExpiresAt: string;
  featureFlags: string[] | null;
  roles: string[] | null;
  isDeleted: boolean;
};

export type ProductAccessDto = {
  productAccessId: string;
  shouldRenew: boolean;
  isEnabled: boolean;
  expiresAt: string;
  user: User | null;
};

export type GetOrganizationProductAccessesResponse = {
  productAccessesByProduct: Record<string, ProductAccessDto[]>;
};

const getOrganizationProductAccesses = async (organizationId: string) => {
  const { data } = await axios.post<GetOrganizationProductAccessesResponse>(
    `/api/Subscription/GetOrganizationProductAccesses`,
    {
      organizationId,
      product: 'TASS',
    },
    {
      baseURL: adminApiUrl,
    }
  );

  return data;
};

const transformResponse = (data: GetOrganizationProductAccessesResponse) => {
  let userAccesses: UserAccess[] = [];
  if (data.productAccessesByProduct['TASS']) {
    data.productAccessesByProduct['TASS']
      .filter((x) => x.user !== null)
      .forEach((access) => {
        userAccesses = [
          ...userAccesses,
          {
            id: access.user!.id,
            email: access.user!.email ?? '',
            firstName: access.user!.firstName ?? '',
            lastName: access.user!.lastName ?? '',
            createdAt: access.user!.createdAt,
            isEnabled: access.isEnabled,
            isAdmin: access.user?.roles?.includes('Admin') ?? false,
            trialExpiresAt: access.user?.trialExpiresAt ?? '',
          },
        ];
      });
  }
  return userAccesses;
};

export const useGetOrganizationProductAccesses = (organizationId: string) => {
  return useQuery<GetOrganizationProductAccessesResponse, unknown, UserAccess[]>(
    ['product-accesses', organizationId],
    () => getOrganizationProductAccesses(organizationId),
    {
      enabled: !!organizationId,
      select: transformResponse,
      onError: () => toast.error('An error occurred while retrieving subscription details!'),
    }
  );
};
