import { FC } from 'react';
import { Link } from 'react-router-dom';
import { AddOutlined, SyncProblemOutlined } from '@mui/icons-material';
import dayjs from 'dayjs';

import { Box, IconButton, InputAdornment, Stack, TextField } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { TertiaryButton } from '@/components/Shared/Buttons';
import { Text } from '@/components/Shared/Text';
import { useFeatureFlags } from '@/components/FeatureToggles';
import { TrblCrankWheelIcon } from '@/components/Icons';
import { useChargebee } from '@/components/Subscriptions/utils/useChargebee';

import { roundFloat } from '@/utils/trebleFunctions';

import { PendingSubscriptionInfo, SubscriptionDetails as SubscriptionDetailsType } from './types';

import styles from './styles.module.scss';

type SubscriptionnDetailsProps = {
  organizationId?: string;
  subscriptionDetails?: SubscriptionDetailsType;
  pendingSubscriptionDetails?: PendingSubscriptionInfo;
  canEdit: boolean;
  isMyOrganization: boolean;
  tokensLeft: number;
  onSubscriptionChanged: () => void;
  onTokensChanged: () => void;
};

export const SubscriptionDetails: FC<SubscriptionnDetailsProps> = ({
  organizationId,
  subscriptionDetails,
  pendingSubscriptionDetails,
  canEdit,
  isMyOrganization,
  tokensLeft,
  onSubscriptionChanged,
  onTokensChanged,
}) => {
  const { automaticBillingFeature } = useFeatureFlags();

  const { openPortal, addTokens } = useChargebee();

  const showPendingTypeChange =
    automaticBillingFeature &&
    pendingSubscriptionDetails &&
    subscriptionDetails &&
    (pendingSubscriptionDetails.subscriptionType !== subscriptionDetails.subscriptionType ||
      pendingSubscriptionDetails.paymentFrequency !== subscriptionDetails.paymentFrequency);

  const showPendingCancellation =
    subscriptionDetails?.chargebeeSubscription?.cancelledAt &&
    subscriptionDetails.chargebeeSubscription.status === 'non_renewing' &&
    automaticBillingFeature;

  const showPendingSeatsChange =
    automaticBillingFeature &&
    pendingSubscriptionDetails &&
    subscriptionDetails &&
    pendingSubscriptionDetails.maxSeats !== subscriptionDetails.maxSeats;

  return (
    <Box component="div" display="flex" flexDirection="column" gap="6px" height="100%">
      <Box component="div" display="flex" justifyContent="space-between" alignItems="center">
        <p style={{ fontWeight: '600' }}>Subscription</p>
        {automaticBillingFeature && canEdit && organizationId && (
          <TrblTooltip title="Manage subscription">
            <IconButton
              onClick={() => openPortal(onSubscriptionChanged)}
              aria-label="Manage subscription"
              size={'small'}
              sx={{ marginBottom: '-2px' }}>
              <TrblCrankWheelIcon width="16" height="16" />
            </IconButton>
          </TrblTooltip>
        )}
      </Box>
      <Box component="div" display={'flex'} flex={'1 1 0'} bgcolor={'#272727'} borderRadius={'4px'}>
        <Stack spacing={1.8} padding="24px 20px 12px 10px" width={'100%'}>
          <Stack flexDirection="row" gap="2">
            <TextField
              className={styles['text-field-input']}
              InputProps={{
                disabled: true,
                endAdornment: (showPendingTypeChange || showPendingCancellation) && (
                  <InputAdornment position="end">
                    <TrblTooltip
                      title={
                        <Box component="div" display="flex" flexDirection={'column'} gap="8px">
                          {showPendingTypeChange ? (
                            <>
                              <Text type="bold-12px">{`Scheduled changes (${dayjs(
                                pendingSubscriptionDetails.scheduledDate
                              ).format('DD/MM/YYYY')})`}</Text>
                              <Text type="medium-12px">{`${pendingSubscriptionDetails.subscriptionType} - ${pendingSubscriptionDetails.paymentFrequency}`}</Text>
                            </>
                          ) : (
                            <Text type="bold-12px">{`Your subscription will be canceled on ${dayjs(
                              subscriptionDetails.chargebeeSubscription?.cancelledAt
                            ).format('DD/MM/YYYY')}`}</Text>
                          )}
                        </Box>
                      }>
                      <Box component={'span'} display="flex" alignItems="center">
                        <SyncProblemOutlined color={showPendingCancellation ? 'warning' : 'inherit'} />
                      </Box>
                    </TrblTooltip>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Subscription type"
              value={
                subscriptionDetails?.paymentFrequency
                  ? `${subscriptionDetails.subscriptionType} - ${subscriptionDetails.paymentFrequency}`
                  : subscriptionDetails?.subscriptionType || ''
              }
            />
            {automaticBillingFeature &&
              (subscriptionDetails?.subscriptionType === 'Trial' ||
                subscriptionDetails?.subscriptionType === 'EarlyBird') &&
              isMyOrganization && (
                <Link to="/subscription" className={styles['custom-link']}>
                  Choose subscription
                </Link>
              )}
          </Stack>
          {automaticBillingFeature && subscriptionDetails?.expiresAt && (
            <TextField
              className={styles['text-field-input']}
              InputProps={{
                disabled: true,
              }}
              label={
                ['Flexible', 'Unlimited'].includes(subscriptionDetails.subscriptionType) ? 'Renewal date' : 'Expires at'
              }
              value={subscriptionDetails.expiresAt ? dayjs(subscriptionDetails.expiresAt).format('DD/MM/YYYY') : ''}
            />
          )}

          <TextField
            className={styles['text-field-input']}
            InputProps={{
              disabled: true,
              endAdornment: showPendingSeatsChange && (
                <InputAdornment position="end">
                  <TrblTooltip
                    title={
                      <Box component="div" display="flex" flexDirection={'column'} gap="8px">
                        <Text type="bold-12px">{`Scheduled changes (${dayjs(
                          pendingSubscriptionDetails.scheduledDate
                        ).format('DD/MM/YYYY')})`}</Text>
                        <Text type="medium-12px">{`${pendingSubscriptionDetails.maxSeats} total seats`}</Text>
                      </Box>
                    }>
                    <Box component={'span'} display="flex" alignItems="center">
                      <SyncProblemOutlined sx={{ width: 22 }} />
                    </Box>
                  </TrblTooltip>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
            label="Seats"
            value={`${subscriptionDetails?.activeSeats || 0} / ${subscriptionDetails?.maxSeats || 0}`}
          />
          {['Flexible', 'Trial'].includes(subscriptionDetails?.subscriptionType ?? '') && (
            <TextField
              className={styles['text-field-input']}
              InputProps={{
                disabled: true,
                endAdornment: automaticBillingFeature && canEdit && (
                  <InputAdornment position="end">
                    <TertiaryButton
                      label="Add tokens"
                      icon={<AddOutlined sx={{ fontSize: '16px' }} />}
                      onClick={() => addTokens(onTokensChanged)}
                      width={'fit-content'}
                    />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Tokens"
              value={roundFloat(tokensLeft, 2)}
            />
          )}
        </Stack>
      </Box>
    </Box>
  );
};
