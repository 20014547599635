import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';

import { useResultsContext } from '@/components/Results/context/ResultsContext';

import { IconButton } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { HighlightedText } from '@/components/Shared/HighlightedText';
import { TrblDeleteIcon } from '@/components/Icons';
import { LibraryRow } from '@/components/LibraryPanel';
import { useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { setElementFocus } from '@/components/LibraryPanel/utils';

import { ResultPresetDtoLibraryItem } from '../types';

import styles from './styles.module.scss';

export const SavedResultRow = ({
  resultPreset,
  setShowPopup,
  searchValue = '',
  onDelete,
  onLoadResultPreset,
}: {
  resultPreset: ResultPresetDtoLibraryItem;
  setShowPopup: (show: boolean) => void;
  searchValue?: string;
  onDelete: (input: ResultPresetDtoLibraryItem) => void;
  onLoadResultPreset: (input: string) => void;
}) => {
  const listItemRef = useRef<HTMLLIElement>(null);

  const [isHighlighted, setIsHighlighted] = useState(false);

  const { highlightedItemId } = useLibraryPanelContext();
  const { selectedPreset } = useResultsContext();

  useEffect(() => {
    if (highlightedItemId === resultPreset.id) {
      setIsHighlighted(true);
      setElementFocus(listItemRef);
    } else {
      setIsHighlighted(false);
    }
  }, [highlightedItemId, listItemRef, selectedPreset]);

  const assignPreset = async (event: React.KeyboardEvent) => {
    event.stopPropagation();
    event.preventDefault();

    onLoadResultPreset(resultPreset.id);
    setShowPopup(false);
  };

  return (
    <LibraryRow
      listItemRef={listItemRef}
      itemId={resultPreset.id}
      isHighlighted={isHighlighted}
      isAssigned={false}
      onAssign={assignPreset}>
      <div className={styles.row_content}>
        {/* Name */}
        <p className={styles.name_column} title={resultPreset.name || ''}>
          <HighlightedText text={resultPreset.name} highlight={searchValue} highlightColor="#00f5ba" />
        </p>
        {/* Space */}
        <TrblTooltip
          title={
            resultPreset.spaceNames.length > 44 ? (
              <div style={{ whiteSpace: 'pre-line' }}>{resultPreset.spaceNames.replaceAll(',', '\n')}</div>
            ) : (
              ''
            )
          }>
          <p className={styles.project_column}>
            <HighlightedText text={resultPreset.spaceNames} highlight={searchValue} highlightColor="#00f5ba" />
          </p>
        </TrblTooltip>
        {/* Project */}
        <TrblTooltip
          title={
            resultPreset.projectNames.length > 44 ? (
              <div style={{ whiteSpace: 'pre-line' }}>{resultPreset.projectNames.replaceAll(',', '\n')}</div>
            ) : (
              ''
            )
          }>
          <p className={styles.project_column}>
            <HighlightedText text={resultPreset.projectNames} highlight={searchValue} highlightColor="#00f5ba" />
          </p>
        </TrblTooltip>

        {/* Created */}
        <p className={styles.basic_column}> {dayjs(resultPreset.createdAt).format('MMM DD YYYY')} </p>
        {/* Created by */}
        <TrblTooltip title={resultPreset.createdByUserEmail.length > 25 ? resultPreset.createdByUserEmail : ''}>
          <p className={styles.email_column}>
            <HighlightedText text={resultPreset.createdByUserEmail} highlight={searchValue} highlightColor="#00f5ba" />
          </p>
        </TrblTooltip>
        {/* Delete */}
        <TrblTooltip title="Delete" disableInteractive>
          <p className="delete-column">
            <IconButton onClick={() => onDelete(resultPreset)}>
              <TrblDeleteIcon />
            </IconButton>
          </p>
        </TrblTooltip>
      </div>
    </LibraryRow>
  );
};
