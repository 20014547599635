import { FC, useState } from 'react';
import { DeleteSharp, EditSharp, LockReset } from '@mui/icons-material';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';

import { TrblTooltip } from '@/components/Shared';
import { ConfirmationDialog } from '@/components/Shared/ConfirmationDialog';

import { useResetPassword } from '../../hooks';

import { UserAccess } from '../types';

import styles from './styles.module.scss';

type RowActionsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: GridRenderCellParams<any, UserAccess, any>;
  onEditUser: (userId: string) => void;
  onDeleteUser: (userId: string) => void;
  isUsingSso: boolean;
};

export const RowActions: FC<RowActionsProps> = ({ params, onEditUser, onDeleteUser, isUsingSso }) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showResendConfirmation, setShowResendConfirmation] = useState(false);

  const { mutate: resetPassword } = useResetPassword();

  return (
    <div className={styles['row-actions-cell']}>
      <TrblTooltip title="Edit user">
        <GridActionsCellItem onClick={() => onEditUser(params.row.id)} icon={<EditSharp />} label="Edit" />
      </TrblTooltip>
      {!isUsingSso && (
        <TrblTooltip title="Send set password email">
          <GridActionsCellItem
            onClick={() => setShowResendConfirmation(true)}
            icon={<LockReset />}
            label="Send set password email"
          />
        </TrblTooltip>
      )}
      <TrblTooltip title="Delete user">
        <GridActionsCellItem onClick={() => setShowDeleteConfirmation(true)} icon={<DeleteSharp />} label="Delete" />
      </TrblTooltip>
      {showDeleteConfirmation && (
        <ConfirmationDialog
          title="Confirm delete"
          message={`Are you sure you want to delete the user with email ${params.row.email}?`}
          onConfirm={() => {
            onDeleteUser(params.row.id);
            setShowDeleteConfirmation(false);
          }}
          onCancel={() => setShowDeleteConfirmation(false)}
        />
      )}
      {showResendConfirmation && (
        <ConfirmationDialog
          title="Confirm send"
          message={`Are you sure you want to send a set password email to ${params.row.email}?`}
          onConfirm={() => {
            resetPassword(params.row.id);
            setShowResendConfirmation(false);
          }}
          onCancel={() => setShowResendConfirmation(false)}
        />
      )}
    </div>
  );
};
