import { FC, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Backdrop, Box } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { PrimaryButton } from '@/components/Shared/Buttons';
import { LoadingSpinner } from '@/components/Shared/LoadingSpinner';
import { useFeatureFlags } from '@/components/FeatureToggles';
import { TrblAddIcon } from '@/components/Icons';
import { useGetUserById } from '@/components/ManagementPortal/hooks/useGetUserById';
import { OrganizationDetails } from './OrganizationDetails';
import { SubscriptionDetails } from './SubscriptionDetails';
import { UsersGrid } from './UsersGrid';
import { UsersGridOld } from './UsersGrid/UsersGridOld';
import { UserFormState, UserSidepanel } from './UserSidepanel';

import {
  useGetOrganizationById,
  useGetOrganizationProductAccesses,
  useGetSubscription,
  useGetTokenStatus,
  useUserActions,
} from '../hooks';
import { useGetPendingSubscriptionChanges } from '../hooks/useGetPendingSubscriptionChanges';

type OrginizationsProps = {
  organizationId: string;
  isMyOrganization: boolean;
};

export const Organization: FC<OrginizationsProps> = ({ organizationId, isMyOrganization }) => {
  const { user } = useAuth0();
  const userRoles: string[] = user ? user['https://treble.tech/roles'] : [];

  const isSuperUser = userRoles.includes('Superuser');
  const { automaticBillingFeature } = useFeatureFlags();
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [hasPendingSubscriptionChanges, setHasPendingSubscriptionChanges] = useState(false);
  const [hasPendingTokenChanges, setHasPendingTokenChanges] = useState(false);

  const [selectedUserId, setSelectedUserId] = useState<string>();

  const { data: organization, isLoading: isLoadingOrganization } = useGetOrganizationById(organizationId);
  const { data: productAccesses } = useGetOrganizationProductAccesses(organizationId);
  const { data: subscriptionDetails } = useGetSubscription(organizationId, hasPendingSubscriptionChanges, () =>
    setHasPendingSubscriptionChanges(false)
  );
  const { data: tokenStatus } = useGetTokenStatus(
    organizationId,
    ['Flexible', 'Trial'].includes(subscriptionDetails?.subscriptionType ?? ''),
    hasPendingTokenChanges,
    () => setHasPendingTokenChanges(false)
  );

  const { data: selectedUser } = useGetUserById(selectedUserId);
  const { data: pendingSubscriptionDetails } = useGetPendingSubscriptionChanges(
    organizationId,
    ['Flexible', 'Unlimited'].includes(subscriptionDetails?.subscriptionType ?? '') && automaticBillingFeature
  );

  // User actions
  const { updateUser, createUser, addUserToOrganization, updateAdminSettings, deleteUser, inProgress } =
    useUserActions(organizationId);

  const usersLoadingState = isLoadingOrganization || inProgress;

  const handleCancelUserSidepanel = () => {
    if (selectedUserId) {
      setSelectedUserId(undefined);
    } else {
      setIsCreatingUser(false);
    }
  };

  const handleAddUserButtonClick = () => {
    setIsCreatingUser(true);
  };

  const handleSubmitUser = async (user: UserFormState) => {
    if (user.id) {
      updateUser({
        firstName: user.firstName,
        lastName: user.lastName,
        userId: user.id,
      });
      setSelectedUserId(undefined);
    } else {
      createUser(
        {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
        },
        {
          onSuccess: (response) => {
            addUserToOrganization({
              userId: response.id,
              organizationId: organizationId,
            });
          },
        }
      );
      setIsCreatingUser(false);
    }
  };

  const handleSubscriptionChanged = () => {
    setHasPendingSubscriptionChanges(true);
  };

  const handleTokensChanged = () => {
    setHasPendingTokenChanges(true);
  };

  return (
    <>
      <Box component={'div'} display="flex" flexDirection="column" gap={'6px'} height="100%" padding="20px 30px">
        <Box component={'div'} display="flex" flexDirection={'row'} gap={'20px'}>
          <Box component={'div'} width={470} mb={'10px'}>
            <OrganizationDetails organization={organization} canEdit={isSuperUser} />
          </Box>
          <Box component={'div'} width={420} mb={'10px'}>
            <SubscriptionDetails
              organizationId={organizationId}
              isMyOrganization={isMyOrganization}
              subscriptionDetails={subscriptionDetails}
              pendingSubscriptionDetails={pendingSubscriptionDetails ?? undefined}
              canEdit={
                isMyOrganization && ['Flexible', 'Unlimited'].includes(subscriptionDetails?.subscriptionType || '')
              }
              tokensLeft={tokenStatus?.tokensLeftInPackages ?? 0}
              onSubscriptionChanged={handleSubscriptionChanged}
              onTokensChanged={handleTokensChanged}
            />
          </Box>
        </Box>
        <Box component={'div'} display="flex" alignItems={'end'} justifyContent={'space-between'}>
          <p style={{ fontWeight: '600' }}>Users</p>
          <TrblTooltip
            title={
              subscriptionDetails && subscriptionDetails.activeSeats >= subscriptionDetails.maxSeats
                ? 'You can not add new users - please add more seats'
                : null
            }>
            <span>
              <PrimaryButton
                label="Add user"
                disabled={subscriptionDetails && subscriptionDetails.activeSeats >= subscriptionDetails.maxSeats}
                icon={<TrblAddIcon fill="none" />}
                onClick={handleAddUserButtonClick}
                width={150}
                sx={{ marginBottom: '2px' }}
              />
            </span>
          </TrblTooltip>
        </Box>
        <Box component="div" flex="1 1 auto">
          {automaticBillingFeature ? (
            <UsersGrid
              organizationId={organizationId}
              isUsingSso={organization?.organization.useSso ?? false}
              isLoading={usersLoadingState}
              hasAvailableSeats={
                subscriptionDetails !== undefined && subscriptionDetails?.activeSeats < subscriptionDetails?.maxSeats
              }
              users={productAccesses ?? []}
              onEditUser={(userId) => setSelectedUserId(userId)}
            />
          ) : (
            <UsersGridOld
              organizationId={organizationId}
              isUsingSso={organization?.organization.useSso ?? false}
              isLoading={usersLoadingState}
              isSuperUser={isSuperUser}
              users={organization?.users || []}
              onEditUser={(userId) => setSelectedUserId(userId)}
              onDeleteUser={(userId) => deleteUser({ organizationId, userId })}
              onToggleIsAdmin={(userId, isAdmin) => updateAdminSettings(userId, isAdmin)}
            />
          )}
        </Box>
        {(isCreatingUser || (selectedUserId && selectedUser?.id === selectedUserId)) && (
          <UserSidepanel user={selectedUser} onCancel={handleCancelUserSidepanel} onSubmit={handleSubmitUser} />
        )}
      </Box>
      <Backdrop
        sx={{ backdropFilter: 'blur(1px)', zIndex: 100 }}
        open={hasPendingSubscriptionChanges || hasPendingTokenChanges}>
        <LoadingSpinner />
      </Backdrop>
    </>
  );
};
