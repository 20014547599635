import { FC, useState } from 'react';

import { TrblToggle } from '@/components/Shared';
import { ConfirmationDialog } from '@/components/Shared/ConfirmationDialog';

type SwitchCellProps = {
  id: string;
  value: boolean;
  disabled: boolean;
  onToggle: (value: boolean) => void;
  confirmationText: string;
};

export const SwitchCell: FC<SwitchCellProps> = ({ id, onToggle, value, disabled, confirmationText }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleToggle = () => {
    setShowConfirmation(true);
  };

  return (
    <>
      <TrblToggle dark id={id} disabled={disabled} checked={value} onChangeToggle={handleToggle} ariaLabel={id} />
      {showConfirmation && (
        <ConfirmationDialog
          title="Confirm change"
          message={confirmationText}
          onConfirm={() => {
            onToggle(!value);
            setShowConfirmation(false);
          }}
          onCancel={() => setShowConfirmation(false)}
        />
      )}
    </>
  );
};
