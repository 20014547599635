import { useAddUserToOrganization } from './useAddUserToOrganization';
import { useCreateUser } from './useCreateUser';
import { useDeleteUser } from './useDeleteUser';
import { useDisableAdmin } from './useDisableAdmin';
import { useEnableAdmin } from './useEnableAdmin';
import { useUpdateUser } from './useUpdateUser';
import { useUpdateUserProductAccess } from './useUpdateUserProductAccess';

export const useUserActions = (organizationId: string) => {
  // Create user
  const { mutate: createUser, isLoading: createUserInProgress } = useCreateUser();
  const { mutate: addUserToOrganization, isLoading: addUserInProgress } = useAddUserToOrganization();

  // Update user
  const { mutate: updateUser, isLoading: updateUserInProgress } = useUpdateUser(organizationId);
  const { mutate: deleteUser, isLoading: deleteUserInProgress } = useDeleteUser();
  const { mutate: enableAdmin, isLoading: enableAdminInProgress } = useEnableAdmin();
  const { mutate: disableAdmin, isLoading: disableAdminInProgress } = useDisableAdmin();
  const { mutate: updateUserProductAccess, isLoading: updateUserProductAccessInProgress } =
    useUpdateUserProductAccess();

  const updateAdminSettings = (userId: string, isAdmin: boolean) => {
    if (isAdmin) {
      enableAdmin({ userId, organizationId });
    } else {
      disableAdmin({ userId, organizationId });
    }
  };

  const enableDisableUser = (userId: string, isEnabled: boolean) => {
    updateUserProductAccess({ userId, isEnabled, organizationId });
  };

  return {
    createUser,
    addUserToOrganization,
    updateUser,
    deleteUser,
    updateAdminSettings,
    enableDisableUser,
    inProgress:
      createUserInProgress ||
      addUserInProgress ||
      updateUserInProgress ||
      enableAdminInProgress ||
      disableAdminInProgress ||
      updateUserProductAccessInProgress ||
      deleteUserInProgress,
  };
};
