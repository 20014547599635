import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';

import { config } from '@/__config__/config';

import { SubscriptionDetails } from '../Organization/types';

import axios from '@/axios';

const { adminApiUrl } = config;

export type ProductDto = {
  productKey: string; // "TASS" or "TSDK"
  billingType: string; // Trial, EarlyBird, Unlimited, PrepaidOnly, OnDemandOnly, Free
  paymentFrequency: string; // Monthly, Yearly
  concurrency: number;
  activeSeats: number;
  maxSeats: number;
  expiresAt: string | null;
};

export type ChargebeeSubscription = {
  cancelledAt: string | null;
  nextBillingAt: string | null;
  currentTermEnd: string | null;
  status: string | null;
};

export type GetSubscriptionResponse = {
  subscriptionId: string;
  products: ProductDto[];
  chargebeeSubscription: ChargebeeSubscription | null;
};

const MAX_RETRIES = 10;

const getSubscription = async (organizationId: string) => {
  const { data } = await axios.post<GetSubscriptionResponse>(
    `/api/Subscription/GetSubscription`,
    {
      organizationId,
      product: 'TASS',
    },
    {
      baseURL: adminApiUrl,
    }
  );

  return data;
};

const transformResponse = (data: GetSubscriptionResponse) => ({
  subscriptionType: data.products[0].billingType === 'PrepaidOnly' ? 'Flexible' : data.products[0].billingType,
  paymentFrequency: data.products[0].paymentFrequency,
  activeSeats: data.products[0].activeSeats,
  maxSeats: data.products[0].maxSeats,
  expiresAt: data.products[0].expiresAt ?? '',
  chargebeeSubscription: data.chargebeeSubscription,
});

/** Check if there is a difference between previous data and newly fetched data (in case of ongoing refetch interval)  */
const compareData = (previousData: SubscriptionDetails, newData: SubscriptionDetails) => {
  return (
    previousData.maxSeats === newData.maxSeats &&
    previousData.subscriptionType === newData.subscriptionType &&
    previousData.paymentFrequency === newData.paymentFrequency &&
    previousData.chargebeeSubscription?.cancelledAt === newData.chargebeeSubscription?.cancelledAt
  );
};

export const useGetSubscription = (
  organizationId: string,
  hasPendingChanges: boolean,
  onPendingChangesRegistered: () => void
) => {
  const [previousData, setPreviousData] = useState<SubscriptionDetails | null>(null);
  const retryCountRef = useRef(0);

  return useQuery<GetSubscriptionResponse, unknown, SubscriptionDetails>(
    ['subscription', organizationId],
    () => getSubscription(organizationId),
    {
      onSuccess: (data) => {
        setPreviousData(data);
      },
      enabled: !!organizationId,
      refetchOnWindowFocus: false,
      onError: () => toast.error('An error occurred while retrieving subscription details!'),
      select: transformResponse,
      refetchInterval: hasPendingChanges
        ? (data) => {
            // If the cached data is different from the new data or we have already waited for 20 sec then we stop polling
            if ((previousData && data && !compareData(previousData, data)) || retryCountRef.current >= MAX_RETRIES) {
              onPendingChangesRegistered();
              retryCountRef.current = 0;

              return false;
            }
            // We wait for 2 sec before polling again
            else {
              retryCountRef.current += 1;
              return 2000;
            }
          }
        : false,
    }
  );
};
